



































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import ContentLayout from '@/modules/course/components/content-layout.vue'
import ExtLearning from '@/modules/course/pages/course-detail/components/ext-learning.vue'
import Students from '@/modules/course/pages/course-detail/components/students.vue'
import ClassList from '@/modules/course/pages/course-class/components/class-list/index.vue'
import ClassCourseInfo from '@/modules/course/pages/course-class/components/class-course-info.vue'
import CourseClassMaterial from '@/modules/course/pages/course-class/components/class-material.vue'

import {
  getCourseMaterialsList,
  getCourseDetail,
} from '@/modules/course/api'
import { CourseInfo } from '@/modules/course/models'

  @Component({
    components: {
      ExtLearning,
      Students,
      ClassList,
      CourseClassMaterial,
      ClassCourseInfo,
      ContentLayout
    }
  })
export default class ClassIndex extends Vue {
    isLoading: boolean = true
    errorObj: any = null

    courseId: string = ''
    mId: string = ''
    materialList: Array<any> = []
    courseDetail: CourseInfo = {}


    created() {

    }
    mounted() {
      this.pageInit()
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
      next()
      this.pageInit()
    }

    get materials() {
      return [{
        course_id: this.courseId,
        id: this.mId
      }]
    }
    get filterList() {
      return this.materialList.filter(v => {
        return !v.bind || !v.bind.class
      })
    }

    getCourse () {
      let id = this.courseId
      return getCourseDetail(id).then(res => {
        if (!res.error) {
          this.courseDetail = res
          if (!this.courseDetail.is_subscribe) {
            this.$router.push({
              name: 'course',
              params: {
                id: this.courseDetail.content_id
              }
            })
          }
        } else {
          this.errorObj = {
            html: res.message || '课程详情服务繁忙'
          }
          this.isLoading = false
        }
      })
    }

    pageInit() {
      this.courseDetail = {}
      this.courseId = this.$route.params.courseId
      this.mId = this.$route.params.mId
      this.isLoading = true
      return this.getCourse().then(() => {
        return this.initMaterialsList().then(() => {
          this.isLoading = false
        })
      })
    }

    initMaterialsList() {
      return getCourseMaterialsList(this.courseId).then(e => {
        this.materialList = e.data
      })
    }

    toMaterial(v: any) {
      if (this.courseDetail.is_subscribe) {
        let query: any = {}
        let {token} = this.$route.query
        if (token) {
          query.token = token
        }
        this.$router.push({
          name: 'courseMaterial',
          params: {
            courseId: this.courseDetail.content_id,
            mId: v.id.toString()
          },
          query
        })
      } else {
        this.$message('加入课程后才能看')
      }
    }

}
